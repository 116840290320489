import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { EstimatesService } from '../services/estimates.service';
import { Subject, Subscription, combineLatest } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationPopupComponent } from 'app/shared/components/confirmation-popup.component';
import { ToastrService } from 'ngx-toastr';
import { ApiSettings } from 'app/settings.class';
import { EntitiesService } from 'app/entities/services/entities.service';

@Component({
  selector: 'con-estimates-layout',
  templateUrl: './estimates-layout.component.html'
})
export class EstimatesLayoutComponent implements OnInit, OnDestroy {

  showMainSideMenu = true;
  showSurveySideMenu = false;
  showSurveyDetailsSideMenu = false;
  public selectedMenu: string = '';
  companySurveys = [];
  surveyMenuListSub: Subscription;
  surveyDataLoaded = false;
  currentSurveyState:'UNSET'| 'IP_APPROVE' | 'APPROVED' | 'IP_UNAPPROVE' | 'UNAPPROVED' = 'UNSET';
  selectedSurveyDetails: any;
  enableSurveyApproveToggle: boolean;
  tooltipText: string = null

  private componentDestroyed$ = new Subject();

  constructor(
    private router: Router, 
    private route: ActivatedRoute, 
    public estimatesService: EstimatesService, 
    private modalService: NgbModal,
    private toaster: ToastrService,
    private toastrService: ToastrService,
    private entityService: EntitiesService
  ) {

  }

  ngOnInit(): void {
    this.findCurrentMenu();
    this.router.events.pipe(
      takeUntil(this.componentDestroyed$),
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.findCurrentMenu();
    });
    this.surveyMenuListSub = this.estimatesService.getCurrentCompanySurveys().subscribe((surveys) => {
      this.companySurveys = surveys;
      this.surveyDataLoaded = true;
    })
    this.getCurrentSurveyDetails();
  }

  onActivate($event: any) {
    if ($event.route && $event.route.hasOwnProperty('data')) {
      $event.route.data.subscribe((routeData: any) => {

        if (routeData.hasOwnProperty('menu')) {

          this.resetMenuViewDisplay();
          if (routeData.menu === 'MainSideMenu') {
            this.showMainSideMenu = true;
          }
          if (routeData.menu === 'SurveySideMenu') {
            this.estimatesService.setConsensusValues(null);
            this.showSurveySideMenu = true;
          }
          if (routeData.menu === 'SurveyDetailsSideMenu') {
            this.showSurveyDetailsSideMenu = true;
            this.selectedSurveyDetails = null
          }
        }
      })
    } else {
      this.resetMenuViewDisplay();
      this.showMainSideMenu = true;
    }

  }

  resetMenuViewDisplay(): void {
    this.showMainSideMenu = false;
    this.showSurveySideMenu = false;
    this.showSurveyDetailsSideMenu = false;
    this.surveyDataLoaded = false;
  }

  setCurrentMenu(menuData: string) {
    this.selectedMenu = menuData;
    const currentUrl = this.router.url.split('/');
    currentUrl[currentUrl.length -1] = menuData
    this.router.navigateByUrl(currentUrl.join('/'));
  }
  
  ngOnDestroy(): void {
    this.surveyMenuListSub.unsubscribe();
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
  goToEstimate(estimate: any) {
    const routeURL = estimate.taxonomy_id ? 'active-consensus' : 'survey-main';
    this.router.navigate([estimate.company_id, 'estimate', estimate.id, routeURL], { relativeTo: this.route.parent });
  }

  backToSurveyList() {
    const companyId = this.route?.firstChild?.snapshot?.params?.companyId;
    if (companyId) {
      this.router.navigate(['estimates', 'company', companyId]);
    }
  }
  
  findCurrentMenu() {
    const url = this.router.url.split('/');
    this.selectedMenu = url[url.length - 1];
    const menuNameLength = this.selectedMenu.indexOf('?');
    if (menuNameLength !== -1) {
      this.selectedMenu = this.selectedMenu.substring(0, menuNameLength);
    }
  }

  getCurrentSurveyDetails() {
    combineLatest([this.estimatesService.getCurrentSelectedSurvey(), this.estimatesService.getConsensusValues()]).pipe(takeUntil(this.componentDestroyed$)).subscribe(([survey, consensus]) => {
      this.currentSurveyState = survey?.locked ? 'APPROVED' : 'UNAPPROVED';
      this.selectedSurveyDetails = survey;
      this.enableSurveyApproveToggle = !!( survey?.taxonomy_id && consensus?.estimates?.length > 0 );
      if(survey && !survey.taxonomy_id && consensus && !consensus.estimates) {
        this.tooltipText = 'No taxonomy and no consensus';
      } else if(survey && !survey.taxonomy_id) {
        this.tooltipText = 'No taxonomy';
      } else if(consensus && !consensus.estimates) {
        this.tooltipText = 'No consensus';
      } else {
        this.tooltipText = null;
      }
    })
  }

  toggleSurveyApprove() {
    this.currentSurveyState = this.currentSurveyState === 'APPROVED' ? 'UNAPPROVED' : 'APPROVED';
    const modalRef = this.modalService.open(ConfirmationPopupComponent, { size: 'md' })
      const modalData = {
        title: this.currentSurveyState === 'APPROVED' ? 'Approve Survey' : 'Unapprove Survey',
        text: `Are you sure you want to ${this.currentSurveyState === 'APPROVED' ? 'approve' : 'unapprove'} this survey?`,
       };
      modalRef.componentInstance.modalData = modalData;
      modalRef.result.then((result) => {
        this.approveSurvey(this.currentSurveyState === 'APPROVED');
      }, 
      (reason) => {
        this.currentSurveyState = this.currentSurveyState === 'APPROVED' ? 'UNAPPROVED' : 'APPROVED';
      })
  }

  approveSurvey(approveStatus = true) {
    this.currentSurveyState = approveStatus ? 'IP_APPROVE' : 'IP_UNAPPROVE';
    this.estimatesService.approveSurvey({locked : approveStatus }).pipe(takeUntil(this.componentDestroyed$)).subscribe((response) => {
      this.estimatesService.setCurrentSelectedSurvey(response);
      this.toastrService.success(`Survey ${approveStatus ? 'approved' : 'unapproved'} successfully`);
      this.estimatesService.setCurrentSurveyApproveStatus(approveStatus)
      this.currentSurveyState = approveStatus ? 'APPROVED' : 'UNAPPROVED';
    }, (err) => {
      this.currentSurveyState = approveStatus ? 'UNAPPROVED' : 'APPROVED';
      if(err?.data && err?.data?.type === ApiSettings.RESPONSES.VALUE_ERROR)
        this.toaster.error(this.entityService.getFirstErrorForToaster(err?.data) || `Failed to ${approveStatus ? 'approve' : 'unapprove'} survey`);
      else
        this.toastrService.error(`Failed to ${approveStatus ? 'approve' : 'unapprove'} survey`);
    })
  }
}
