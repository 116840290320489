import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {SharedModule} from 'app/shared/shared.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {EntitiesModule} from 'app/entities/entities.module';
import {RouterModule} from '@angular/router';
import {MomentModule} from 'ngx-moment';
import {StartComponent} from './layout/start/start.component';
import {CompanyReportsComponent} from './layout/company-reports/company-reports.component';
import {NewReportComponent} from './component/new-report/new-report.component';
import {NewReportFileRelationComponent} from './component/new-report-file-relation/new-report-file-relation.component';
import {NewReportFileComponent} from './component/new-report-file/new-report-file.component';
import {EmployeeComponent} from './component/employee/employee.component';
import {ShareholderComponent} from './component/shareholder/shareholder.component';
import {IncomeStatementComponent} from './component/income-statement/income-statement.component';
import {IncomeStatementEditorComponent} from './component/income-statement/income-statement-editor.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IMaskModule} from 'angular-imask';
import {TreeLayoutComponent} from './component/income-statement/tree-layout.component';
import {BalanceSheetComponent} from './component/balance-sheet/balance-sheet.component';
import {BalanceSheetEditorComponent} from './component/balance-sheet/balance-sheet-editor.component';
import {BalanceSheetTreeLayoutComponent} from './component/balance-sheet/tree-layout.component';
import {CashFlowComponent} from './component/cash-flow/cash-flow.component';
import {CashFlowEditorComponent} from './component/cash-flow/cash-flow-editor.component';
import {CashFlowTreeLayoutComponent} from './component/cash-flow/tree-layout.component';
import {PeriodKpiComponent} from './layout/period-kpi/period-kpi.component';
import {SnapshotKpiComponent} from './layout/snapshot-kpi/snapshot-kpi.component';
import {KpiEditorComponent} from './component/kpi-editor/kpi-editor.component';
import {LoadDivDirective} from './component/kpi-editor/load-div.directive';
import {StaticModule} from 'app/static/static.module';
import {KeyFinancialsComponent} from './component/key-financials/key-financials.component';
import {CompanyReportFilesSideMenuComponent} from './layout/side-menus/company-report-files-side-menu/company-report-files-side-menu.component';
import {CompanyReportSideMenuComponent} from './layout/side-menus/company-report-side-menu/company-report-side-menu.component';
import {MainSideMenuComponent} from './layout/side-menus/main-side-menu/main-side-menu.component';
import { CompanyCheckComponent } from './component/company-check/company-check.component';
import { CompanyReportEventsComponent } from './component/company-report-events/company-report-events.component';
import { CompanyStatusComponent } from './component/company-status/company-status.component';
import { CompanyReportComponent } from './component/company-report/company-report.component';
import { AddNewCompanyReportComponent } from './component/add-new-company-report/add-new-company-report.component';
import { MissingCompanyReportsComponent } from './component/missing-company-reports/missing-company-reports.component';
import { AddNewReportEventComponent } from './component/add-new-report-event/add-new-report-event.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import { SendToMlDialogComponent } from './component/send-to-ml-dialog/send-to-ml-dialog.component';
import { ReportEventsEditorComponent } from './component/report-events-editor/report-events-editor.component';
import { MissingReportEventsComponent } from './component/missing-report-events/missing-report-events.component';
import { ReportEventsStartComponent } from './component/report-events-start/report-events-start.component';
import { FilterReportComponent } from './component/filter-report/filter-report.component';
import { StatementsListingComponent } from './component/statements-listing/statements-listing.component';
import { CombinedStatementsEditViewComponent } from './component/new-report-file/combined-statements-edit-view/combined-statements-edit-view.component';
import { EventsTableComponent } from './component/events-table/events-table.component';
import { MissingEventsComponent } from './component/missing-events/missing-events.component';
import { ReportEventFilterComponent } from './component/report-event-filter/report-event-filter.component';
import { ReportEventStatusComponent } from './component/report-event-status/report-event-status.component';
import { ReportEventComponent } from './component/report-event/report-event.component';
import { UpcomingEventsComponent } from './component/upcoming-events/upcoming-events.component';
import { ReportingStandardComponent } from './component/reporting-standard/reporting-standard.component';
import { ReportingStandardStartComponent } from './component/reporting-standard-start/reporting-standard-start.component';
import { MismatchReportingStandardComponent } from './component/mismatch-reporting-standard/mismatch-reporting-standard.component';
import { ReportDatesComponent } from './component/report-dates/report-dates.component';
import { CommonCommentComponent } from './component/common-comment/common-comment.component';
import { CalendarModule } from 'app/calendar/calendar.module';

@NgModule({
  declarations: [
    CompanyReportFilesSideMenuComponent,
    CompanyReportSideMenuComponent,
    MainSideMenuComponent,
    StartComponent,
    CompanyReportsComponent,
    NewReportComponent,
    NewReportFileComponent,
    EmployeeComponent,
    ShareholderComponent,
    NewReportFileRelationComponent,
    IncomeStatementComponent,
    IncomeStatementEditorComponent,
    TreeLayoutComponent,
    BalanceSheetComponent,
    BalanceSheetEditorComponent,
    BalanceSheetTreeLayoutComponent,
    CashFlowComponent,
    CashFlowEditorComponent,
    CashFlowTreeLayoutComponent,
    PeriodKpiComponent,
    SnapshotKpiComponent,
    KpiEditorComponent,
    LoadDivDirective,
    KeyFinancialsComponent,
    CompanyCheckComponent,
    CompanyReportEventsComponent,
    CompanyStatusComponent,
    CompanyReportComponent,
    AddNewCompanyReportComponent,
    MissingCompanyReportsComponent,
    AddNewReportEventComponent,
    SendToMlDialogComponent,
    ReportEventsEditorComponent,
    MissingReportEventsComponent,
    ReportEventsStartComponent,
    FilterReportComponent,
    StatementsListingComponent,
    CombinedStatementsEditViewComponent,
    EventsTableComponent,
    MissingEventsComponent,
    ReportEventFilterComponent,
    ReportEventStatusComponent,
    ReportEventComponent,
    UpcomingEventsComponent,
    ReportingStandardComponent,
    ReportingStandardStartComponent,
    MismatchReportingStandardComponent,
    ReportDatesComponent,
    CommonCommentComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgbModule,
    EntitiesModule,
    RouterModule,
    MomentModule,
    FormsModule,
    IMaskModule,
    StaticModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    CalendarModule
  ],
  providers: [DatePipe],
  exports: [
    ReportEventFilterComponent
  ],
  entryComponents: [
    SendToMlDialogComponent
  ]
})
export class CompanyReportsModule {
}
