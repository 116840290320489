import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EntitiesModule } from '../entities/entities.module';
import { RouterModule } from '@angular/router';
import { MomentModule } from 'ngx-moment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { StaticModule } from '../static/static.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { EstimatesStartComponent } from './layout/estimates-start.component';
import { EstimatesLayoutComponent } from './layout/estimates-layout.component';
import { NewSurveyComponent } from './component/new-survey/new-survey.component';
import { SurveyDetailMainComponent } from './component/survey-detail-main/survey-detail-main.component';
import { SurveyMainComponent } from './component/survey-main/survey-main.component';
import { SurveyReportComponent } from './component/survey-report/survey-report.component';
import { CompanyCoverageComponent } from './component/company-coverage/company-coverage.component';
import { CustomTaxonomyComponent } from './component/survey-main/custom-taxonomy/custom-taxonomy.component';
import { FormNodeComponent } from './component/survey-main/form-node/form-node.component';
import { ConsenusSnapshotComponent } from './component/consenus-snapshot/consenus-snapshot.component';
import { PostConsensusSnapshotComponent } from './component/post-consensus-snapshot/post-consensus-snapshot.component';
import { PreConsensusSnapshotComponent } from './component/pre-consensus-snapshot/pre-consensus-snapshot.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DragulaModule } from 'ng2-dragula';
import { NgSelectModule } from '@ng-select/ng-select';
import { ActiveConsensusComponent } from './component/active-consensus/active-consensus.component';
import { CoverageListComponent } from './component/coverage-list/coverage-list.component';
import { ExcelImportModalComponent } from './component/excel-import-modal/excel-import-modal.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { FilterSelectedPipe } from './pipes/filter-selected.pipe';
import { ConsensusCoverageComponent } from './component/consensus-coverage/consensus-coverage.component';


@NgModule({
  declarations: [
    EstimatesStartComponent,
    EstimatesLayoutComponent,
    NewSurveyComponent,
    SurveyDetailMainComponent,
    SurveyMainComponent,
    SurveyReportComponent,
    CompanyCoverageComponent,
    CustomTaxonomyComponent,
    FormNodeComponent,
    ConsenusSnapshotComponent,
    PostConsensusSnapshotComponent,
    PreConsensusSnapshotComponent,
    ActiveConsensusComponent,
    CoverageListComponent,
    ExcelImportModalComponent,
    FilterSelectedPipe,
    ConsensusCoverageComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgbModule,
    EntitiesModule,
    RouterModule,
    MomentModule,
    FormsModule,
    IMaskModule,
    StaticModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    DragDropModule,
    DragulaModule,
    NgSelectModule,
    NgxFileDropModule
  ],
  providers: [DatePipe],
  entryComponents: [
  ]
})
export class EstimatesModule {
}
