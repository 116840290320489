<div class="container-fluid mb-3">
  <div class="row">
    <div class="col-12">
      <div class="page-title mb-2 pb-3 pt-3">
        <h3 class="mb-0">Company Estimates</h3>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="!loading && company">
    <div class="col-12">
      <div class="d-flex justify-content-start sticky-header non-sticky py-2">
        <h3 class="mb-0">
          <a routerLink="../../../entity/company/{{company?.id}}"><span class="badge badge-dark">{{company?.name}}</span></a>
          <span *ngIf="companyWebsite"><a [href]=companyWebsite class="link-fontsize" target="_blank">{{getStripedWebsiteString(companyWebsite)}}</a></span>
        </h3>
        <div class="d-flex align-items-center ml-auto">
          <ng-container *ngIf="!isAPISyncLoading; else toggleLoader">
            <div>API sync</div>
            <label class="switch mx-2" [ngbTooltip]="(!isAPISyncEnabled ? 'Enable' : 'Disable') + ' company-wide API sync'">
              <input type="checkbox" [checked]="isAPISyncEnabled" (click)="openToggleConfirmModal()">
              <span class="slider round"></span>
            </label>
          </ng-container>
          <ng-template #toggleLoader>
            <div *ngIf="isAPISyncLoading" class="mr-2">
              <div class="spinner-border spinner-border-sm"></div>
              <span>{{isAPISyncEnabled ? 'Enabling...' : 'Disabling...'}}</span>
            </div>
          </ng-template>
          <div class="chip chip-inverse">
            <con-avatar [userId]="company?.manager?.id" [size]="32"></con-avatar>
            {{company?.manager?.first_name}} {{company.manager?.last_name}}
          </div>
        </div>
      </div>
      <div>
          <div class="card mb-2 custom-hwidth">
            <div class="card-header">
              <con-icon-by-name iconName="paperclip" [mutations]="['fw']"></con-icon-by-name> New company survey
            </div>
            <div class="card-body">
              <con-entity-form-content entityName="Survey" [showWhenChanged]="false"
                                      [entity]="entitySurvey"
                                      [emptyAfterSave]="true"
                                      (afterSave)="surveySaved($event)"
                                      [preFillValues]="prefillSurvey"
                                      [fixedValues]="{company_id: company.id}" [canDelete]="false"
                                      toastMessage="New survey saved successfully!"
                                      [useCustomURL]="true" 
                                      [customLabels]="{ taxonomy_id: 'Taxonomy to clone' }"
                                      [customFields]="surveyCustomFields"
                                      [dynamicValueFill]="{taxonomy_name : taxonomyNameGenerationConfig }">
              </con-entity-form-content>
            </div>
          </div>
      </div>

    </div>
  </div>
  <con-loader *ngIf="loading"></con-loader>
  <div>

    <!-- list items start -->

    <div>
      <div class="card mb-2"  *ngFor="let survey of companySurveys; let index = index">
        <div class="card-header">
          <div class="d-flex justify-content-start align-items-center" (click)="toggleSurveyItem(survey)">
            <con-icon-by-name iconName="database" [mutations]="['fw']" class="mr-1 cursor-pointer" (click)="reportService.goToEntity($event, 'survey', survey?.id )"></con-icon-by-name>
            {{estimatesService.getCompanySurveyName(survey)}}
            <div class="card-header-toggle-individual ml-auto">
              <con-icon-by-name iconName="angle-right" [mutations]="getMutations(survey)" ></con-icon-by-name>
            </div>
          </div>
        </div>
        <div class="p-4 col-6" *ngIf='!survey.hide'>
          <con-entity-form-content [entity]="survey" entityName="Survey"
                                   (afterSave)="surveySaved($event)" [canEdit]="true"
                                   [fixedValues]="{ company_id: company?.id }" [showWhenChanged]="false" [canDelete]="false"
                                   toastMessage="Company Survey updated successfully!">
          </con-entity-form-content>
        </div>
      </div>
      <!-- list items end -->

    </div>
  </div>
</div>
