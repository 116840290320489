<ng-template #rt let-r="result" let-t="term">
  {{ entityDescription.getPrimaryString(r) }}
</ng-template>
<div class="form-group" [class.has-danger]="searchFailed" [ngClass]="{'mb-0': isSmallSizeInput}">
  <con-loader *ngIf="isLoading()"></con-loader>
  <div class="input-group" *ngIf="!isLoading()" [ngClass]="{'input-group-sm': isSmallSizeInput}">
    <div class="input-group-prepend">
      <span class="input-group-text" [ngbTooltip]="entityName | prettyCase">
        <con-icon-by-name [iconName]="entityDescription.getIcon()" [mutations]="['fw']"></con-icon-by-name>
      </span>
    </div>
    <input [disabled]="!_canIndex" type="search" class="form-control" [(ngModel)]="model" (ngModelChange)="entitySelected($event)"
      [ngbTypeahead]="search" [resultTemplate]="rt" placeholder="Search" #searchInput/>
    <div class="input-group-append" *ngIf="searching">
      <span class="input-group-text"><con-loader></con-loader></span>
    </div>
    <div *ngIf="showDismiss" (click)="dismiss()">
      <button class="btn btn-light">
        <con-icon-by-name iconName="remove"></con-icon-by-name>
      </button>
    </div>
  </div>
  <div class="form-control-feedback" *ngIf="searchFailed">Sorry, suggestions could not be loaded.</div>
</div>
